<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<div v-loading="loading">
			<el-card class="box-card order-list">
				<div slot="header" class="clearfix">
					<el-breadcrumb separator="/">
						<el-breadcrumb-item :to="{ path: '/member/order_list' }">我的订单</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/member/order_detail?order_id=' + orderId }">订单详情</el-breadcrumb-item>
						<el-breadcrumb-item>退款</el-breadcrumb-item>
					</el-breadcrumb>
				</div>

				<!--商品信息-->
				<div class="goods-list">
					<table>
						<tr>
							<td width="62.5%">商品</td>
							<td width="12.5%">数量</td>
							<td width="12.5%">金额</td>
						</tr>
					</table>
				</div>
				<div class="goods-list" v-if="refundData.order_goods && refundData.order_goods.length">
					<table>
						<tr v-for="(item,index) in refundData.order_goods" :key="index">
							<td width="62.5%">
								<div class="goods-info">
									<div class="goods-info-left">
										<router-link :to="{ path: '/sku-' + item.sku_id }" target="_blank">
											<img
												class="goods-img"
												:src="$img(item.sku_image, { size: 'mid' })"
												@error="item.sku_image = defaultGoodsImage"
											/>
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/sku-' + item.sku_id }" target="_blank">
											<div class="goods-name">{{ item.sku_name }}</div>
										</router-link>
									</div>
								</div>
							</td>
							<td width="12.5%" class="goods-num">{{ item.num }}</td>
							<td width="12.5%" class="goods-money">￥{{ item.goods_money }}</td>
						</tr>
					</table>
				</div>
			</el-card>
			
			<!--退款类型 -->
			<div class="item-block" v-if="refundData.refund_data && Number(refundData.refund_data.bulk_can_refund_money) > 0">
				<div class="block-text">退款类型</div>

				<div class="pay-type-list">
					<div class="pay-type-item" :class="refundType == 1 ? 'active' : ''" @click="selectRefundType(1)">退款无需退货</div>
					<div v-if="refundData.refund_data.bulk_can_refund_goods_list &&  refundData.refund_data.bulk_can_refund_goods_list.length" class="pay-type-item" :class="refundType == 2 ? 'active' : ''" @click="selectRefundType(2)">退货退款</div>
					<div class="clear"></div>
				</div>
			</div>


			<div class="take-delivery-info pay_info" v-if="refundType == 2">
				<el-table :data="refundData.order_goods" border style="width: 100%"  :row-class-name="rowClassName">
				    <el-table-column prop="item" label="商品" width="350">
						<template slot-scope="item">
							<div class="goods_item">
								<img :src="$img(item.row.sku_image)" alt="">
								<div class="goods_info">
									<div class="name">{{item.row.sku_name}}</div>
								</div>
							</div>
						</template>
				    </el-table-column>
				    <el-table-column prop="price" label="价格(元)" >
				    </el-table-column>
				    <el-table-column prop="bulk_can_refund_num" label="可维权数量">
				    </el-table-column>
					<!-- <el-table-column prop="item" label="退款金额" width="180">
						<template slot-scope="item">
							<el-input  placeholder="输入退款金额" v-model="refundForm[item.row.index].refund_apply_money" class="ns-len-input"></el-input>
						</template>
					</el-table-column> -->
					<el-table-column prop="item" label="退货数量" width="180" >
						<template slot-scope="item">
							<el-input type="number" v-model.trim="refundForm[item.row.index].apply_num"  placeholder="输入退货数量" class="ns-len-input"></el-input>
						</template>
					</el-table-column>
				  </el-table>
			</div>
			<!--退款填写-->
			<div class="item-block">
				<div class="block-text"></div>

				<el-form ref="form" label-width="80px" class="refund-form" v-if="refundData.refund_data">
					<el-form-item label="退款金额" class="money_big">
						<div class="max-money" style="display: flex;">
							<el-input type="number" placeholder="输入退款金额" v-model="refundMoney"></el-input>
							<span class="fixed-bnox">最多可退款 <span class="price-color"> {{refundData.refund_data.bulk_can_refund_money}}</span>元</span>
						</div>
						
					</el-form-item>
					<el-form-item label="退款原因" required>
						<el-select placeholder="请选择" v-model="refundReason">
							<el-option v-for="(item, itemIndex) in refundData.refund_data.refund_reason_type" :key="itemIndex" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="退款说明">
						<el-input maxlength="140" show-word-limit resize="none" rows="5" placeholder="请输入退款说明（选填）" type="textarea" v-model="refundRemark"></el-input>
					</el-form-item>
				</el-form>
			</div>

			<div class="item-block">
				<div class="order-submit"><el-button type="primary" class="el-button--primary" @click="submit">提交</el-button></div>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { refundData, refund, detail, delivery } from '@/api/order/refund';
	import { memberOrderDetail } from '@/api/bulkOrder';
import { mapGetters } from 'vuex';

export default {
	name: 'refund',
	components: {},
	data: () => {
		return {
			changevalue:'',
			orderGoodsId: '',
			orderId: '',
			refundType: 1,
			refundReason: '',
			refundRemark: '',
			isIphoneX: false,
			refundData: {
				refund_type: [],
				order_goods_info: {
					sku_image: ''
				}
			},
			refundMoney:'',
			refundForm:[],
			isSub: false,
			show_type: 0, //退款状态 1-待退款 2-已退款
			detail: {
				refund_action: []
			},
			loading: true,
			yes: true
		};
	},
	created() {
		if (this.$route.query.order_goods_id) this.orderGoodsId = this.$route.query.order_goods_id;
		if (this.$route.query.order_id) this.orderId = this.$route.query.order_id;
		this.getRefundData();
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	methods: {
		 rowClassName({row, rowIndex}) {
			//把每一行的索引放进row
			row.index = rowIndex;
			console.log(row)
		},
		/**
		 * 选择退款方式
		 * @param {Object} type
		 */
		selectRefundType(type) {
			this.refundType = type;
		},
		getRefundData() {
			memberOrderDetail({ order_id: this.orderId })
				.then(res => {
					const { code, message, data } = res;
					if (code >= 0) {
						this.refundData = data;
						this.refundForm = [];
						this.refundData.order_goods.forEach((item,index)=>{
							var obj = {};
							obj.apply_num = ''
							obj.order_goods_id = item.order_goods_id
							this.refundForm.push(obj);
						})
					} else {
						this.$message({
							message: '未获取到该订单项退款信息！',
							type: 'warning',
							duration: 2000,
							onClose: () => {
								this.$router.push({ path: '/member/activist' });
							}
						});
					}
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.$message.error({
						message: err.message,
						duration: 2000,
						onClose: () => {
							this.$router.push({ path: '/member/activist' });
						}
					});
				});
		},
		submit() {
			console.log(this.refundData)
			if (this.verify()) {
				if (this.isSub) return;
				this.isSub = true;
				var submit_data ={}
				if(this.refundType == 1){
					submit_data = {
						order_id:this.refundData.order_id,
						refund_type:1,
						refund_remark:this.refundRemark,
						refund_apply_money:this.refundMoney,
						refund_reason:this.refundReason,
					}
				}else{
					 submit_data = {
						order_id:this.refundData.order_id,
						refund_type:2,
						refund_remark:this.refundRemark,
						refund_apply_money:this.refundMoney,
						refund_order_goods:JSON.stringify(this.refundForm),
						refund_reason:this.refundReason,
					}
				}
				console.log(submit_data)
				refund(submit_data)
					.then(res => {
						const { code, message, data } = res;
						if (code >= 0) {
							this.$message({
								message: '申请成功！',
								type: 'success',
								duration: 2000,
								onClose: () => {
									this.$router.push({ path: '/member/activist' });
								}
							});
						} else {
							this.isSub = false;
							this.$message({ message: message, type: 'warning' });
						}
					})
					.catch(err => {
						this.$message.error({
							message: err.message,
							duration: 2000,
							onClose: () => {
								// this.$router.push({ path: '/member/activist' });
							}
						});
					});
			}
		},
		verify() {
			if(this.refundType == 2){
				if(this.refundForm.findIndex(target=>target.apply_num <= 0 )!=-1){
					this.$message({ message: '请输入退款数量', type: 'warning' });					return false;
				}
			}
			if(this.refundMoney <= 0){
				this.$message({ message: '请输入退款金额', type: 'warning' });
				return false;
			}
			if (this.refundReason == '') {
				this.$message({ message: '请选择退款原因', type: 'warning' });
				return false;
			}
			return true;
		}
	}
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
	box-shadow: unset;
}

.el-card {
	border: 0;
}
.clear {
	clear: both;
}
.item-block {
	padding: 0 15px 1px;
	margin: 10px 0;
	border-radius: 0;
	border: none;
	background: #ffffff;
	.block-text {
		border-color: #eeeeee;
		color: $ns-text-color-black;
		padding: 7px 0;
		border-bottom: 1px;
	}
}
.refund-form {
	width: 350px;
	.el-select {
		width: 100%;
	}
}
.order-submit {
	text-align: center;
	padding: 10px;
}
.goods-list {
	padding: 15px 0;
	table {
		width: 100%;
	}
	.goods-info-left {
		width: 60px;
		height: 60px;
		float: left;
		.goods-img {
			width: 60px;
			height: 60px;
		}
	}
	.goods-info-right {
		float: left;
		height: 60px;
		margin-left: 10px;
		color: $base-color;
		width: 80%;
		.goods-name {
			line-height: 20px;
			padding-top: 10px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
		.goods-spec {
			color: #999;
		}
	}
}
.pay-type-list {
	padding: 20px 0;
}
.pay-type-item {
	display: inline-block;
	border: 2px solid #eeeeee;
	padding: 5px 20px;
	margin-right: 20px;
	cursor: pointer;
}
.pay-type-item.active {
	border-color: $base-color;
}
.status-wrap {
	color: #999;
}
.media-left {
	float: left;
}
.media-right {
	float: right;
	i.rotate {
		transform: rotate(180deg);
		transition: 0.3s;
	}
}
.action-box {
	padding: 10px 0;
}
.action-way {
	float: left;
	color: #999;
}
.head .time {
	float: right;
	color: #999;
}
.record-item {
	margin-bottom: 10px;
}
.order-statistics {
	float: left;
	padding: 10px;
	// color: #999;
}
.el-textarea .el-input__count {
	line-height: 20px;
}
	
		.take-delivery-info {
			padding:15px;
			background-color: #fff;
			margin-bottom: 10px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 0 0 33.3333%;
					margin-bottom: 10px;

					&:last-child {
						flex: initial;
					}
				}
			}
			.el-timeline{
				display: block;
				li{
					margin: 0 !important;
				}
			}
		}
		.pay_info{
			h4{
				display: flex;
				align-items: center;
				.el-progress{
					width: 20%;
					margin-left: 20px;
				}
			}
			h5{
				font-weight: normal;
				margin-bottom: 10px;
				font-size: 14px;
			}
			.payment_voucher{
				width: 60px;
				height: 60px;
				display: block;
			}
			.goods_item{
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				img{
					width: 60px;
					height: 60px;
					display: block;
					margin-right: 10px;
				}
				.goods_info{
					display: flex;
					flex-direction: column;
					flex: 1;
					.name{
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
			}
			.package_names{
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				.name{
					font-size: 14px;
					line-height: 18px;
					padding: 6px 10px;
					background-color: #fff;
					border: 1px solid #c6c6c6;
					border-radius: 5px;
					margin-right: 15px;
					cursor: pointer;
					&.active{
						color: #fff;
						background-color: #00a98f;
						border-color: #00a98f;
					}
				}
			}
			.package_info{
				display: flex;
				align-items: flex-start;
				.left_info{
					width: 35%;
					margin-right: 30px;
					.item{
						display: flex;
						align-items: center;
						margin-bottom: 10px;
						font-size: 14px;
						line-height: 18px;
						.tit{
							min-width: 75px;
						}
					}
					.goods_info{
						margin-bottom: 10px;
						display: flex;
						align-items: center;
						img{
							width: 60px;
							height: 60px;
							display: block;
							margin-right: 10px;
						}
						.info{
							.name{
								font-size: 14px;
								line-height: 18px;
								margin-bottom: 8px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
							}
							.num{
								line-height: 16px;
							}
						}
					}
				}
				.right_info{
					flex: 1;
					max-height: 500px;
					overflow-y: auto;
					padding-left: 10px;
					.package_status{
						margin-bottom: 10px;
					}
				}
			}
		}
		.max-money{
			position: relative;
		}
		.fixed-bnox{
			position: absolute;
			right: -150px;
			color:#a8a8a8;
			.price-color{
				color: #00A98F;
			}
		}
</style>
